@import url(https://db.onlinewebfonts.com/c/30e0683e6bf9e4a0b87d920a188910f9?family=Cancellaresca+Script+Std);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(112, 144, 118);
  color: hsl(0, 0%, 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
  letter-spacing:  0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: hsl(210, 22%, 49%);
}
.brand-title
{
  color: hsl(60, 62%, 90%);
  font-size: 2.3em;
  font-family: "Cancellaresca Script Std";
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  font-weight: bolder;

}

.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}
.menu {
  padding: 5rem 0;
}
.title {
  text-align: center;
  margin-bottom: 2rem;
}

.underline {
  width: 6rem;
  height: 0.25rem;
  background: #c59d5f;
  margin-left: auto;
  margin-right: auto;
}

.btn-container {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}

.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 1.3rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color:  hsl(75, 75%, 60%);
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 0.25rem;
}

.active {
  border-bottom: 2px solid;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  gap: 3rem 2rem;
  justify-items: center;
}

.menu-item {
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
}

.photo {
  object-fit: cover;
  height: 100px;
  width: 100%;
  border-radius: 0.25rem;
  display: block;
}

.logo {
  object-fit: cover;
  height: auto;
  width: 250px;
  border-radius: 0.25rem;  
}

.item-info header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px dotted hsl(0, 0%, 100%);
}
.item-info h4 {
  margin-bottom: 0.5rem;
}
.price {
  color: #c59d5f;
}
.item-text {
  margin-bottom: 0;
  padding-top: 1rem;
  color: rgb(217, 219, 221);
}


@media screen and (min-width: 768px) {
  .menu-item {
    grid-template-columns: 225px 1fr;
    gap: 0 1.25rem;
    max-width: 40rem;
   
  }
  .photo {
    height: 175px;
  }
}
@media screen and (min-width: 1200px) {
  .section-center {
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }
  .photo {
    height: 150px;
  }
}